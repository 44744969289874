import React, { Component } from 'react';
import * as contentful from 'contentful';
import resources from './resources.js';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Photo from './photo.js';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import * as Markdown from 'react-markdown';



class ProductionCategory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      asset_id: null,
      category_title: null,
      picture_url: null,
      category_text: null,
    };
  }

  client = contentful.createClient({
    space: resources.data.space,
    accessToken: resources.data.accessToken
  })

  componentDidMount(){
    this.client.getEntry(this.props.id)
      .then((entry) => {
        this.setState({
          asset_id: entry.fields.productionCategoryPicture.sys.id,
          category_title: entry.fields.productionCategoryTitle,
          category_text: entry.fields.productionCategoryDescription,
        });
        this.client.getAsset(this.state.asset_id)
          .then((asset) => {
            this.setState({picture_url: asset.fields.file.url});
          })
          .catch(console.error);
      })
      .catch(console.error);
  }

  render(){
    console.log(this.state.category_title);
    console.log(this.state.category_text);
    return (

      <div className="ProductionCategory">
        <div className="productionCategory col-lg-12 row">
          <div className="col-lg-12 row content-left">
            <div><h1>{this.state.category_title}</h1></div>
          </div>
          <div className="col-lg-12 row content-left">
            <div className="col-lg-5 content-left"><img alt='bock' align='left' class="bock" src={this.state.picture_url}/></div>
            <Markdown source={this.state.category_text}/>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductionCategory;
