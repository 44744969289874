var theme_resources = {

  space: '2potjglsqrdx',
  accessToken: 'cd2fbf03d3690b9b4adfeeeec4f47778c372155185703ed9ae2741d9aed4ad38',
  base_url: 'http://fritiofsson.com'


}

module.exports = {
  data: theme_resources
}
