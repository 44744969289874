import React, { Component } from 'react';
import * as contentful from 'contentful';
import resources from './resources.js';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as Markdown from 'react-markdown';


class Intro extends Component {


  constructor(props) {
    super(props);
    this.state = {
      logo_url: null,
      intro_text: 'introtext',
    };
  }

  client = contentful.createClient({
    space: resources.data.space,
    accessToken: resources.data.accessToken
  })



  componentDidMount(){
    this.client.getEntries()
    .then(entries => {
      entries.items.forEach(entry => {
        if(entry.fields) {
          this.setState({items: entry.fields});
        }
      })
    });

    this.client.getAsset('5wq6J87LoWqgQ0A62kWIQ2')
      .then((asset) => {
        this.setState({logo_url: asset.fields.file.url});
      })
      .catch(console.error);

    this.client.getEntry('3s7LiNDta7ajZB0DX2F0Bc')
      .then((entry) => {
        console.log(entry.fields.intro);
        this.setState({intro_text: entry.fields.intro});
      })
      .catch(console.error);

  }



  render() {


    return (
      <div className="Intro">
        <div className="col-lg-12 row">
          <div className="col-lg-1 sideline row">
            <div className="pink-line"> </div>
            <img alt='olivia_fritiofsson' class="profile full-width animated flipInX delay-1s" src={require('./img/Bock_korall.png')}/>
          </div>
          <div className="col-lg-11">
            <div className="row middle-xs content-left">
              <div className="col-lg-1">
              </div>
              <div className="col-lg-3 col-xs-5">
                <div className="logo"><a href="/#/"><img className="logo col-lg-12 col-md-6" alt="ob-logo" src={this.state.logo_url}></img></a></div>
              </div>
            </div>
            <div className="row middle-xs">
              <div className="col-lg-1"></div>
              <div className="col-lg-11">
                <div className="introText">
                  <Markdown source={this.state.intro_text} />
                </div>
              </div>
          </div>

            <div className="col-lg"> </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
