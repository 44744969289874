import React, { Component } from 'react';
import * as contentful from 'contentful';
import resources from './resources.js';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Photo from './photo.js';
import ProductionCategory from './production-category.js';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';



class Production extends Component {


  constructor(props) {
    super(props);
    this.state = {
      production_categories: [],
    };
  }

  client = contentful.createClient({
    space: resources.data.space,
    accessToken: resources.data.accessToken
  })

  componentDidMount(){
    this.client.getEntries()
    .then(entries => {
      entries.items.forEach(entry => {
        if(entry.fields) {
          if(entry.sys.contentType.sys.id == 'productionCategory') {
            this.setState(prevState => ({
              production_categories: [...prevState.production_categories, entry.sys.id]
            }));
          }
        }
      })
    })
  }

  render(){
    console.log(this.state.production_categories);
    const categories = this.state.production_categories;
    const categoryList = categories.map((category) =>
      <li className="center-xs col-lg-12 row" key={category.toString()}>
        <ProductionCategory id={category}> </ProductionCategory>
      </li>

    );



    return (

      <div className="Production">
        <h1>Produktion</h1>
        <p>Produktion Intro</p>

        <div className="row col-lg-12">
          <div className="col-lg-1"></div>
          <div className="col-lg-11">
            <ul className="col-log-12 row">{categoryList}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Production;
