import React, { Component } from 'react';
import * as contentful from 'contentful';
import resources from './resources.js';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Photo from './photo.js';
import Strategy from './strategy.js';
import Design from './design.js';


import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Production from './production.js';


class CategoryTab extends Component {


  constructor(props) {
    super(props);
    this.state = {
      logo_url: null,
    };
  }

  client = contentful.createClient({
    space: resources.data.space,
    accessToken: resources.data.accessToken
  })



  componentDidMount(){
    this.client.getEntries()
    .then(entries => {
      entries.items.forEach(entry => {
        if(entry.fields) {
          this.setState({items: entry.fields});

        }
      })
    })

    this.client.getAsset('5wq6J87LoWqgQ0A62kWIQ2')
      .then((asset) => {
        this.setState({logo_url: asset.fields.file.url});
      })
      .catch(console.error)
  }


  render() {
    return (
      <div className="CategoryTab middle-xs col-lg-4">
        <Nav.Item className="category-tab">
          <Nav.Link eventKey={this.props.catID} href={"/#" + this.props.catID + "/"}>
            <div id="tab-parent">
              <h1 className="tab-title">{this.props.name}</h1>
              <div id="tab-hover-content">
                <h1 className="">{this.props.name}</h1>
                Only show this when hovering parent
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
      </div>
    );
  }
}

export default CategoryTab;
