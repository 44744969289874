import React, { Component } from 'react';
import * as contentful from 'contentful';
import resources from './resources.js';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Photo from './photo.js';
import Strategy from './strategy.js';
import Design from './design.js';
import CategoryTab from './category-tab.js';


import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Production from './production.js';


class Categories extends Component {


  constructor(props) {
    super(props);
    this.state = {
      logo_url: null,
    };
  }

  client = contentful.createClient({
    space: resources.data.space,
    accessToken: resources.data.accessToken
  })



  componentDidMount(){
    this.client.getEntries()
    .then(entries => {
      entries.items.forEach(entry => {
        if(entry.fields) {
          this.setState({items: entry.fields});

        }
      })
    })

    this.client.getAsset('5wq6J87LoWqgQ0A62kWIQ2')
      .then((asset) => {
        this.setState({logo_url: asset.fields.file.url});
      })
      .catch(console.error)
  }


  render() {
    return (
      <div className="Categories">
        <div className="col-lg-12">
          <div className="row top-xs">
            <Tab.Container  id="left-tabs-example" defaultActiveKey="first">
              <div className="row category-tabs col-lg-12 top-xs">
                <div className="col-lg-10">
                  <Nav variant="pills" className="top-xs">
                    <CategoryTab catID='strategi' name="Strategi"/>
                    <CategoryTab catID='profil' name="Profil"/>
                    <CategoryTab catID='produktion' name="Produktion"/>
                  </Nav>
                </div>
              </div>
            </Tab.Container>
            <div>
              <Switch>
                <Route path='/strategi/' render={(props) => <Strategy {...props}  />} />
                <Route path='/profil/' render={(props) => <Design {...props}  />} />
                <Route exact path='/produktion/' render={(props) => <Production {...props}  />} />
                <Route path='/produktion/photo' render={(props) => <Photo {...props}  />} />
              </Switch>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default Categories;
