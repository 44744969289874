import React, { Component } from 'react';
import * as contentful from 'contentful';
import resources from './resources.js';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as Markdown from 'react-markdown';
import ReactContactForm from 'react-mail-form';
import axios from 'axios';


class ContactForm extends Component {


  constructor(props) {
    super(props);
    this.state = {
        name: '',
        message: '',
        email: '',
        sent: false,
        buttonText: 'Send Message'
      }
    };


  client = contentful.createClient({
    space: resources.data.space,
    accessToken: resources.data.accessToken
  })

  handleSubmit(e){
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const message = document.getElementById('message').value;

    var data = {
      name: name,
      email: email,
      message: message
    }

    console.log("data: " + data.message);

    e.preventDefault();
    axios({
      method: "POST",
       url: "http://localhost:5000/form/",
       data: data
    }).then((response)=>{
        if (response.data.msg === 'success'){
            alert("Message Sent.");
            this.resetForm()
        }else if(response.data.msg === 'fail'){
            alert("Message failed to send.")
        }
    })
    console.log('testmail');
  }

  resetForm(){
      document.getElementById('contact-form').reset();
  }

  componentDidMount(){
    this.client.getEntries()
    .then(entries => {
      entries.items.forEach(entry => {
        if(entry.fields) {
          this.setState({items: entry.fields});
        }
      })
    });

    this.client.getAsset('5wq6J87LoWqgQ0A62kWIQ2')
      .then((asset) => {
        this.setState({logo_url: asset.fields.file.url});
      })
      .catch(console.error);

    this.client.getEntry('3s7LiNDta7ajZB0DX2F0Bc')
      .then((entry) => {
        console.log(entry.fields.intro);
        this.setState({intro_text: entry.fields.intro});
      })
      .catch(console.error);

  }



  render() {


    return (
      <div className="ContactForm">
        <div className="col-lg-12">
          <div>
            <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                   <div className="form-group">
                       <label for="name">Name</label>
                       <input type="text" className="form-control" id="name" />
                   </div>
                   <div className="form-group">
                       <label for="exampleInputEmail1">Email address</label>
                       <input type="email" className="form-control" id="email" aria-describedby="emailHelp" />
                   </div>
                   <div className="form-group">
                       <label for="message">Message</label>
                       <textarea className="form-control" rows="5" id="message"></textarea>
                   </div>
                   <button type="submit" className="btn btn-primary">Submit</button>
               </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
