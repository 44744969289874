import React, { Component } from 'react';
import * as contentful from 'contentful';
import resources from './resources.js';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as Markdown from 'react-markdown';
import ReactContactForm from 'react-mail-form';
import ContactForm from './contact-form.js';

class Sidebar extends Component {


  constructor(props) {
    super(props);
    this.state = {
      logo_url: null,
      intro_text: 'introtext',
    };
  }

  client = contentful.createClient({
    space: resources.data.space,
    accessToken: resources.data.accessToken
  })



  componentDidMount(){
    this.client.getEntries()
    .then(entries => {
      entries.items.forEach(entry => {
        if(entry.fields) {
          this.setState({items: entry.fields});
        }
      })
    });

    this.client.getAsset('5wq6J87LoWqgQ0A62kWIQ2')
      .then((asset) => {
        this.setState({logo_url: asset.fields.file.url});
      })
      .catch(console.error);

    this.client.getEntry('3s7LiNDta7ajZB0DX2F0Bc')
      .then((entry) => {
        console.log(entry.fields.intro);
        this.setState({intro_text: entry.fields.intro});
      })
      .catch(console.error);

  }


  render() {


    return (
      <div className="Sidebar">
        <div className="col-lg-12">
          <div className="row hide-xs show-md middle-xs">
            <img alt='olivia_fritiofsson' class="profile full-width" src={require('./img/olivia_fritiofsson.jpg')}/>
            <p>Olivia Fritiofsson</p>
            <p>+46 (0) 730 88 52 30</p>
            <p>olivia@oliviabeatrice.se</p>
          </div>
          <ContactForm />

        </div>
      </div>
    );
  }
}

export default Sidebar;
