import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Intro from './intro.js';
import Categories from './categories.js';
import Sidebar from './sidebar.js';
import { scaleDown as Menu } from 'react-burger-menu';

class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      logo_url: null,
      intro_text: 'introtext',
    };
  }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})
  }

  closeMenu () {
    this.setState({menuOpen: false})
  }




  render() {
    return (
      <div className="App" id="outer-container">
        <div className="bm hide-large">
          <Menu pageWrapId={ "page-container" } outerContainerId={ "outer-container" } isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)} right>
            <a onClick={() => this.closeMenu()} id="Strategy" className="menu-item" href="/#/strategy/">Strategi</a>
            <a onClick={() => this.closeMenu()} id="Design" className="menu-item" href="/#/design/">Design</a>
            <a onClick={() => this.closeMenu()} id="Production" className="menu-item" href="/#/production/">Produktion</a>
            <a onClick={() => this.closeMenu()} id="Om-mig" className="menu-item" href="/#/about/">Om mig</a>
          </Menu>
        </div>
        <div className="col-lg-12 page-container row" id="page-container">
          <div className="col-lg-10 col-md-12 content-container">
            <div className="row">
              <div className="col-lg-12">
                <Intro> </Intro>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 green-box">
                <Categories> </Categories>
              </div>
            </div>
            <div className="row">
              <div className=" blue-box col-lg-12">
                <h1>  </h1>
              </div>
            </div>
          </div>

          <div className="col-md-2 hide-medium sidebar row">
            <Sidebar />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
